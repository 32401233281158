import React from "react";
import './projects.css';
import Weather from '../../assets/weather.jpeg';
import Subreddit from '../../assets/subreddit.jpeg';
import Casl from '../../assets/CASL-Screens.png';
import AI from '../../assets/AI-Screens.png';
import Eye from '../../assets/EyePhy-Screens.png';
import Disney from '../../assets/MouseClass-Screens.png';
import Divider from '../Divider/Divider';
import Events from '../../assets/event.png';
import Friendbook from '../../assets/friendbook-home.jpg'
import NASA from '../../assets/NASA.png';
import Crypto from '../../assets/Crypto.png'

const Projects = () => {
    const images = [
        {
            id: 1,
            image: Friendbook,
            title: 'Social Media App',
            desc: 'Full Stack App - Share your photos with closest friends. Cloudinary, Bootstrap, Express.js, MongoDB, Node.js, Passport Auth',
            github: 'https://github.com/netmagik/friendbook',
            demo: 'https://web-production-5c14.up.railway.app/',
        },
        {
            id: 2,
            image: Weather,
            title: 'Weather App',
            desc: 'Get local weather by location. Toggle b/t Celcius and Fahrenheit. Get 5 day forecast. Search by City Name',
            github: 'https://github.com/netmagik/Weather-App',
            demo: 'https://netmagik.github.io/Weather-App/',
        },
        {
            id: 3,
            image: NASA,
            title: 'NASA Photo of the Day',
            desc: 'Enter a date to load NASA Photo of the day of our universe. NASA API, Eleventy, Netlify Functions',
            github: 'https://github.com/netmagik/nasa',
            demo: 'https://nasa-photo-of-the-day8080.netlify.app/',
        },
        {
            id: 4,
            image: Events,
            title: 'Event Planning App',
            desc: 'Full Stack app to plan events, invite others, add items/volunteer opportunities. Express.js, MongoDB, Node.js, Passport Auth, TailwindCSS',
            github: 'https://github.com/netmagik/events',
            demo: 'https://events-production.up.railway.app/',
        },
        {
            id: 5,
            image: Crypto,
            title: 'Crypto Currency Conversion',
            desc: 'Converts Digital Currency to Dollars. React, Netlify Functions',
            github: 'https://github.com/netmagik/cryptocurrency-rates',
            demo: 'https://cryptocurrency-rates.netlify.app/',
        },
        {
            id:6,
            image: Subreddit,
            title: 'Subreddit Viewer',
            desc: 'Search for Subreddits and load articles using Reddit API.',
            github: 'https://github.com/netmagik/Subreddit-feed-app',
            demo: 'https://netmagik.github.io/Subreddit-feed-app/',
        },
    ];

    const websites = [
        {
            id: 1,
            image: AI,
            title: 'UCF AI Initiative',
            desc: 'Website to highlight AI activities at UCF. It features faculty directory and their contribution to AI research at UCF. Listing of AI research projects, areas of interest, educational programs, AI courses taught and contact information. ',
            about: 'https://www.netmagik.com/portfolio/website-for-ucf-ai-initiative/',
        },
        {
            id: 2,
            image: Casl,
            title: 'UCF Research Lab',
            desc: 'Website for CASL Research Lab, specializing in Data Analytics. It features research projects, papers, faculty directory & information about MS in Data Analytics.',
            about: 'https://www.netmagik.com/portfolio/website-for-ucf-research-laboratory/',
        },
        {
            id: 3,
            image: Eye,
            title: 'Eye Physicians',
            desc: 'Website for a large medical practice providing information to patients, referring doctors and research studies.',
            about: 'https://www.netmagik.com/portfolio/website-medical-practice/',
        },
        {
            id: 4,
            image: Disney,
            title: 'Disney Travel',
            desc: 'Website for a busy Disney travel agency providing information to clients interested in Disney Travel.',
            about: 'https://www.netmagik.com/portfolio/website-for-disney-travel-agency/',
        },
    ]
    
    return (
        <section id="projects">
            <Divider />
            <h2>Sample Projects</h2>
            <div className="container websites-container">
               {
                     websites.map(({id, image, title, about, desc}) => {
                         return (
                            <article key={id} className="projects-item">
                                <div className="projects-item-image">
                                    <img className="single-image" src={image} alt={title} loading="lazy"/>
                                </div>
                                <h3>{title}</h3>
                                <p className="desc">{desc}</p>
                            </article>
                         );
                     })
                }
            </div>
           < Divider />
           <p className="sub-title">React Web Applications</p>
            <div className="container projects-container">
               {
                     images.map(({id, image, title, github, demo, desc}) => {
                         return (
                            <article key={id} className="projects-item">
                                <div className="projects-item-image">
                                    <img className="single-image" src={image} alt={title} loading="lazy"/>
                                </div>
                                <h3>{title}</h3>
                                <p className="desc">{desc}</p>
                            </article>
                         );
                     })
                }
            </div>
           
        </section>
    ); 
}

export default Projects;